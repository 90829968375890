import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate();
   // Password validation function
   const validatePassword = (value) => {
    // Check for minimum length, uppercase, lowercase, number, and special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);

    if (!validatePassword(value)) {
      setError(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
    } else {
      setError("");
    }
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    

    setLoading(true);
    
    try {
      const response = await axios.post(`https://needia.in/api/reset-password/${token}`, {  password: newPassword });
      if (response.data) {
        setSuccess("Password reset successfully. You can now log in.");
        setTimeout(()=>
        {
          navigate("/")

        },1000)
      } else {
        setError("Unable to reset password. Please try again.");
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

 return (
    <div className="reset-password-page d-flex align-items-center justify-content-center min-vh-100 px-3"
   style={{ backgroundImage: 'url("https://storage.googleapis.com/background-static-images/Needia%20backgorund%20image.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="reset-password-form card shadow p-4 w-100" style={{ maxWidth: "400px" }}>
        <h2 className="text-primary mb-4 text-center">Reset Password</h2>
        <form onSubmit={handleResetPassword}>
          <div className="form-group mb-3">
            <label htmlFor="newPassword" className="form-label">New Password</label>
            <div className="input-group">
              <input
                className="form-control"
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                value={newPassword}
                placeholder="Enter new password"
                onChange={handlePasswordChange}
                required
              />
              <button
                type="button"
                className="btn btn-outline-white position-absolute end-0 top-50 translate-middle-y"
                onClick={toggleNewPasswordVisibility}
                style={{
                  textDecoration: "none",
                  padding: 0,
                  width: "2.5rem",
                  height: "2.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
              </button>
            </div>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
            <div className="input-group">
              <input
                className="form-control"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                value={confirmPassword}
                placeholder="Confirm new password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                required                
              />
              <button
                type="button"
                className="btn btn-outline-white position-absolute end-0 top-50 translate-middle-y"
                onClick={toggleConfirmPasswordVisibility}
                style={{
                  textDecoration: "none",
                  padding: 0,
                  width: "2.5rem",
                  height: "2.5rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </button>
            </div>
          </div>

          <button type="submit" className="btn btn-primary w-100" disabled={loading}>
            {loading ? "Resetting..." : "Reset Password"}
          </button>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
          {success && <div className="alert alert-success mt-3">{success}</div>}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
