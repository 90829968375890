import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserSignup from './UserSignup';
import VolunteerSignup from './VolunteerSignup';

function Signup() {
  const [role, setRole] = useState('user'); // State for toggling between user and volunteer

  return (
    <div className="login-page d-flex align-items-center justify-content-center overflow-hidden"  >
      <div className="login-form card shadow p-4 w-100" style={{ maxWidth: "600px",maxHeight:"700px" }}>
        <h3 className="text-primary mb-1">Signup</h3>
        {/* Role Toggle */}
        <div className="form-group mb-0">
          <label className="form-label">Signup as:</label>
          <div className="d-flex">
            <div className="form-check me-3">
              <input
                type="radio"
                id="userRole"
                name="role"
                value="user"
                checked={role === 'user'}
                onChange={(e) => setRole(e.target.value)}
                className="form-check-input"
              />
              <label htmlFor="userRole" className="form-check-label">User</label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                id="volunteerRole"
                name="role"
                value="volunteer"
                checked={role === 'volunteer'}
                onChange={(e) => setRole(e.target.value)}
                className="form-check-input"
              />
              <label htmlFor="volunteerRole" className="form-check-label">Volunteer</label>
            </div>
          </div>
        </div>

        {/* Conditionally Render User or Volunteer Signup Form */}
        {role === 'user' ? (
          <UserSignup />
        ) : (
          <VolunteerSignup />
        )}
      </div>
    </div>
  );
}

export default Signup;

