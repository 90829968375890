import React, { useState } from "react";
import axiosInstance from "./Interceptors/axiosInterceptor";
import { useNavigate, Link } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./lo.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Lo = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const validateGmail = (value) => /^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(value);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUsername(value);

    if (!validateGmail(value)) {
      setError("Please enter a valid Gmail address.");
    } else {
      setError("");
    }
  };
  async function handleLogin(e) {
  e.preventDefault();
  setLoading(true);
  setError("");
  try {
   
    const response = await axiosInstance.post("/Login", { username, password });
   // console.log("Response received:", response.data);
    if (response.data.user) {
      localStorage.setItem("userId", response.data.user.userId);
      localStorage.setItem("token", response.data.token);
      // console.log("Login successful");
      navigate("/home/post", { state: { id: username } });
    } else {
      setError("Unexpected response format. Please try again.");
    }
  } catch (error) {
   // console.error("Login error:", error);
    if (error.response) {
     // console.log("Error status:", error.response.status);
     // console.log("Error response data:", error.response.data);
      if (error.response.status === 401) {
        setError("Invalid username or password.");
      } else {
        setError("An error occurred. Please try again later.");
      }
    } else {
      setError("An error occurred. Please try again later.");
    }
  } finally {
    setLoading(false);
  }
}


  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="login-page d-flex align-items-center justify-content-center min-vh-100">
      <div
        className="login-form card shadow p-4 w-100"
        style={{ maxWidth: "400px" }}
      >
        <h1 className="text-center text-primary">NEEDIA</h1>
        <h2 className="text-primary mb-4">Login</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group mb-3">
            <label htmlFor="username" className="form-label">
              Username
            </label>
            <input
              className="form-control"
              type="text"
              id="username"
              value={username}
              placeholder="Enter your username"
              onChange={handleEmailChange}
              required
              aria-label="Username"
            />
          </div>
          <div className="form-group mb-3 position-relative">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              className="form-control"
              type={passwordVisible ? "text" : "password"}
              id="password"
              value={password}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
              required
              aria-label="Password"
              style={{ paddingRight: "40px" }} // Ensure padding to avoid overlap with the icon
            />
            <button
              type="button"
              className="btn btn-link position-absolute btn-sm"
              style={{
                textDecoration: "none",
                right: "10px",
                top: "50%",
                transform: "translateY(10%)",
                width:"20px",
                padding:0,
              }}
              onClick={togglePasswordVisibility}
              aria-label={passwordVisible ? "Hide password" : "Show password"}
            >
              <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
            </button>
          </div>

          <button
            type="submit"
            className="btn btn-primary w-100"
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login"}
          </button>
          {error && <div className="alert alert-danger mt-3">{error}</div>}
          <div className="text-center mt-3">
            <p>OR</p>
            <Link to="/Signup" className="btn btn-link">
              Signup
            </Link>
          </div>
          <div className="text-center mt-2">
  <Link to="/forgot-password" className="btn btn-link">
    Forgot Password?
  </Link>
</div>
        </form>
      </div>
    </div>
  );
};

export default Lo;
