import React, { createContext, useState } from 'react';

// Create a context for profile data
const ProfileContext = createContext();

const ProfileProvider = ({ children }) => {
  // State to hold the profile data
  const [profile, setProfile] = useState(null);
  //console.log("inside the ProfileProvider");

  return (
    // Use the Provider to pass down profile and setProfile to children components
    <ProfileContext.Provider value={{ profile, setProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileContext, ProfileProvider };
