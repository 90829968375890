import React, { useState} from "react";
import axios from "axios";


const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  async function handleForgotPassword(e) { 
    e.preventDefault();
    setLoading(true);
    setError("");
    setMessage("");

    try {
      const response = await axios.post("https://needia.in/api/forgot-password", { username });
      if (response.data) {
        setMessage("Password reset link has been sent to your username email.");
      } else {
        setError("Unable to process request. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
      console.error("Error during forgot password:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
   <div className="forgot-password-page d-flex align-items-center justify-content-center min-vh-100 bg-light"
    style={{ backgroundImage: 'url("https://storage.googleapis.com/background-static-images/Needia%20backgorund%20image.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
  <div className="forgot-password-form card shadow-sm p-4 w-100 mx-2 mx-md-auto" style={{ maxWidth: "400px" }}>
    <h2 className="text-primary mb-4 text-center">Forgot Password</h2>
    <form onSubmit={handleForgotPassword}>
      <div className="form-group mb-3">
        <label htmlFor="username" className="form-label">
          Enter your username
        </label>
        <input
          className="form-control"
          type="text"
          id="username"
          value={username}
          placeholder="Enter your username"
          onChange={(e) => setUsername(e.target.value)}
          required
          aria-label="Username"
        />
      </div>
      <button type="submit" className="btn btn-primary w-100" disabled={loading}>
        {loading ? "Sending link..." : "Send Reset Link"}
      </button>
      {message && <div className="alert alert-success mt-3">{message}</div>}
      {error && <div className="alert alert-danger mt-3">{error}</div>}
    </form>
  </div>
</div>

  );
};

export default ForgotPassword;
