import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import Lo from './Components/Lo';
import Home from './Components/Home';
import Signup from './Components/Signup';
import PublicLayout from './Components/PublicLayout';
import AuthLayout from './Components/AuthLayout';
import Profile from './Components/Profile';
import Search from './Components/Search';
import ProfileDisplay from './Components/ProfileDisplay';
import EditProfile from './Components/EditProfile';
import { ProfileProvider } from './Components/ProfileContext';
import DashboardLayout from './Components/DashboardLayout'; // Import the new layout
import ForgotPassword from './Components/ForgotPassword';
import ResetPassword from './Components/ResetPassword';
import FeedbackForm from './Components/FeedbackForm';
function App() {
  return (
    <Router>
      <Routes>

        {/* Public Routes */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Lo />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword/>} />

        </Route>

        {/* Authenticated Routes */}
        <Route element={<ProfileProvider><AuthLayout /></ProfileProvider>}>
        <Route element={<Outlet/>}>
          <Route path="/home/*" element={<Home />} />
          <Route path="/profile/:id" element={<ProfileDisplay  />} />
          <Route path="/edit-profile/:userId" element={<EditProfile />} />
          <Route path="/search" element={<Search />} />
          <Route path="/FeedbackForm" element={<FeedbackForm />} />
        </Route>
        </Route>

      </Routes>
    </Router>
  );
}

export default App;

