import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import axiosInstance from "./Interceptors/axiosInterceptor";
import "./Post.css";
import { faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShareModal from "./ShareModal";
import ShimmerPlaceholder from "./ShimmerPlaceholder";
import useSocket from "./useSocket";
//import pat1 from "https://storage.googleapis.com/bucket1-for-backgroundimages/patient12.jpg";
//import QR from "https://storage.googleapis.com/bucket1-for-backgroundimages/NEED%20payment%20QR.jpg";

const Post = ({ onSharePost, selectedPost }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [isVerifiedVolunteer, setIsVerifiedVolunteer] = useState(false);
  const [viewedReceipts, setViewedReceipts] = useState({});
  const [receiptUrls, setReceiptUrls] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const postRefs = useRef({});
  const socketOptions = useMemo(
    () => ({
      transports: ["websocket"],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
    }),
    []
  );

  const socket = useSocket("https://needia.in", socketOptions);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axiosInstance.get("/posts");
        const postsData = response.data;
        const postsWithCorrectedUrls = postsData.map((post) => ({
          ...post,
          imageUrl: `https://needia.in/api/${post.imageUrl}`,
          receiptUrl: post.receiptUrl,
        }));
        setPosts(postsWithCorrectedUrls);
        fetchCurrentUserDetails();
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCurrentUserDetails = async () => {
      const userId = localStorage.getItem("userId");
      if (userId) {
        try {
          const response = await axiosInstance.get(`/user-details/${userId}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
          //console.log("Headers being sent:", {Authorization: `Bearer ${localStorage.getItem("token")}`, });

          const { role, isVerified, username } = response.data;
         // console.log("before if block");
          if (username === "myakalashekar12@gmail.com") {
            //console.log("inside the username validation in post.js");
            setIsAdmin(true);
          }

          //console.log("response.data from fetchCurrentUserDetails===>",response.data);
          const volunteerId = response.data.volunteerId;
          localStorage.setItem("volunteerId", volunteerId); // Store volunteerId in localStorage
          if (role === "volunteer" && isVerified) {
            setIsVerifiedVolunteer(true);
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };


    fetchPosts();

    if (socket) {
      socket.on("newPost", (newPost) => {
        setPosts((prevPosts) => [newPost, ...prevPosts]);
      });
      socket.on("postVerified", (updatedPost) => {
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post._id === updatedPost._id ? updatedPost : post
          )
        );
      });
      return () => {
        socket.off("newPost");
        socket.off("postVerified");
      };
    }
  }, [socket]);

  useLayoutEffect(() => {
    if (selectedPost && posts.length > 0) {
      setTimeout(() => {
        const postElement = postRefs.current[selectedPost];
        if (postElement) {
          postElement.scrollIntoView({ behavior: "auto", block: "center" });
          postElement.classList.add("highlighted-post");
          setTimeout(() => {
            postElement.classList.remove("highlighted-post");
          }, 2000);
        }
      }, 2500);
    }
  }, [selectedPost, posts]);

  const handleLike = async (postId) => {
    try {
      const response = await axiosInstance.post(
        `/posts/${postId}/like`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setPosts(
        posts.map((post) =>
          post._id === postId
            ? {
                ...post,
                liked: response.data.liked,
                likes: response.data.likes,
              }
            : post
        )
      );
    } catch (error) {
      console.error("Error liking post:", error);
    }
  };

  const handleShare = (post) => {
    setCurrentPost(post);
    setIsShareModalOpen(true);
  };

  const handleUserSelection = (user) => {
    if (currentPost) {
      onSharePost(user, currentPost);
    }
    setIsShareModalOpen(false);
  };

  const handleVerifyPost = async (postId) => {
    try {
      const volunteerId = localStorage.getItem("volunteerId");
      const response = await axiosInstance.post("/verifyPost", {
        postId,
        volunteerId,
      });
      const updatedPost = response.data.post;
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post._id === updatedPost._id ? updatedPost : post
        )
      );
    } catch (error) {
      console.error("Error verifying post:", error);
    }
  };


  // Upload the selected receipt file for a post
  const handleReceiptUpload = async (postId) => {
    if (!selectedFile) {
      alert("please select the file");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("receipt", selectedFile); // Append the selected file

      const response = await axiosInstance.post(
        `/posts/${postId}/upload-receipt`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Ensure the response contains the receipt data
      if (
        response.data &&
        response.data.receipt &&
        response.data.receipt.filename
      ) {
        const { filename } = response.data.receipt;

        // Update the posts state with the new receipt URL
        setPosts((prevPosts) =>
          prevPosts.map((post) =>
            post._id === postId ? { ...post, receiptUrl: filename } : post
          )
        );

        setSelectedFile(null); // Clear file input after successful upload
      } else {
        console.error("Receipt filename is missing in the response");
      }
    } catch (error) {
      console.error("Error uploading receipt:", error);
    }
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Save the selected file
  };
  // View Receipt by fetching it via filename
  const handleViewReceipt = (filename, postId) => {
    const url = `https://needia.in/api/image/${filename}`; // Adjust the base URL if needed
    window.open(url, "_blank");
    // Mark the QR code as hidden for the post
    setViewedReceipts((prev) => ({ ...prev, [postId]: true }));
  };

  if (loading) {
    return (
      <div className="container-fluid bg-body-secondary shimmer-container">
        <ShimmerPlaceholder />
        <ShimmerPlaceholder />
        <ShimmerPlaceholder />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid bg-body-secondary p-2 mb-4">
  {posts.length > 0 ? (
    posts.map((post) => {
      const isVideo = post.imageUrl.endsWith(".mp4");
      const isVerified = post.status === "verified";
      const verificationProgress = `${post.verifiedBy.length} / ${post.requiredVerifications}`;

      return (
        <div
          key={post._id}
          id={`${post._id}`}
          className="post-container mb-3"
          ref={(el) => {
            postRefs.current[post._id] = el;
          }}
        >
          <p className="post-text text-black">
            <strong>{post.user.fullName}</strong>
          </p>

          <div className="post border-top">
            {isVideo ? (
              <div className="ratio ratio-16x9">
                <video controls className="w-100 h-100">
                  <source src={post.imageUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <img
                src={post.imageUrl}
                alt={post.caption}
                loading="lazy"
                className="img-fluid"
                onError={(e) => {
                  console.error(`Failed to load image: ${post.imageUrl}`);
                  if (!e.target.hasError) {
                    e.target.hasError = true;
                    e.target.src = "fallback-image.jpg";
                  }
                }}
              />
            )}
          </div>

          <div className="post-content">
            <h3 className="post-title">{post.caption}</h3>
            <p className="post-text">
              <small className="text-muted">
                <strong>Date:</strong>{" "}
                {new Date(post.date).toLocaleDateString()}
              </small>
            </p>

            <div
              className={`post-status ${
                isVerified ? "verified" : "processing"
              }`}
            >
              {isVerified ? (
                <div className="d-flex flex-column flex-md-row align-items-center">
                  <div className="text-center text-md-start">
                    <span className="badge bg-success">
                      InPerson NSS volunteers Verified
                    </span>
                    <div>+91 9505224036</div>
                    <h5>Please donate to the below UPI ID</h5>
                    <h6>UPI ID: myakalashekar12-okhdfcbank</h6>
                  </div>
                  {!viewedReceipts[post._id] && (
                    <img
                      src="https://storage.googleapis.com/background-static-images/NEED%20payment%20QR.jpg"
                      alt="QR code for payment"
                      loading="lazy"
                      className="img-fluid ms-md-3 my-2 my-md-0"
                      style={{ width: "150px", height: "150px" }}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <span className="badge bg-warning">Processing</span>
                  <small> (Verifications: {verificationProgress})</small>
                </div>
              )}

              {isAdmin && isVerified && (
                <div className="d-flex align-items-center mt-2">
                  <input
                    type="file"
                    accept="image/*,application/pdf"
                    onChange={handleFileChange}
                    className="form-control me-2 bg-secondary"
                  />
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => handleReceiptUpload(post._id)}
                  >
                    Upload Receipt
                  </button>
                </div>
              )}
            </div>

            {post.receiptUrl && (
              <div className="text-center mt-3">
                <button
                  className="btn btn-sm btn-success"
                  style={{ width: "100px" }}
                  onClick={() =>
                    handleViewReceipt(post.receiptUrl, post._id)
                  }
                >
                  View Receipt
                </button>
                <p className="text-success mt-3 fs-6">
                  🌟 <strong>You're a true hero!</strong> 🌟 Your kindness is
                  not just a donation—it’s a beacon of hope for someone in
                  need. Every gesture of generosity on our platform creates
                  ripples of change in the lives of those who need it most.
                  Together, we are building a community where compassion
                  connects us all. 💕
                </p>
                <p className="mt-2 text-primary fs-6">
                  Thank you for choosing us to be a part of your journey to
                  make the world a better place. Keep shining, and join us
                  again to transform more lives—because here, every small act
                  of giving becomes a big story of hope. 🌍✨
                </p>
              </div>
            )}

            <div className="post-actions mt-2 d-flex align-items-center">
              <FontAwesomeIcon
                icon={faHeart}
                className={`post-icon me-2 ${
                  post.liked ? "liked" : ""
                }`}
                onClick={() => handleLike(post._id)}
                style={{
                  color: post.liked ? "pink" : "white",
                  fontSize: "20px",
                }}
              />
              <FontAwesomeIcon
                icon={faShare}
                className="post-icon me-2"
                onClick={() => handleShare(post)}
                style={{ fontSize: "20px" }}
              />
              <span className="post-likes">{post.likes} likes</span>

              {!isVerified && isVerifiedVolunteer && (
                <div className="mx-3">
                <button
                  className="btn btn-primary btn-sm ms-auto m"
                  onClick={() => handleVerifyPost(post._id)}
                >
                  Verify Post
                </button>
                </div>
              )}
            </div>
          </div>

          <hr
          className="mt-2"
                   style={{
                     width: "100%",
                     height: "2px",
                     backgroundColor: "black",
                    border: "solid",
                    margin: "0 auto",
                   }}
                 />
        </div>
      );
    })
  ) : (
    <div className="post-container border border-primary mb-2 text-center">
      <p className="post-text text-black">
        <strong>User:</strong> NEEDIA
      </p>
      <div className="post border-top">
        <img
          src="https://storage.googleapis.com/background-static-images/Needia%20backgorund%20image.jpg"
          alt="Help for Future India"
          loading="lazy"
          className="img-fluid"
        />
      </div>
      <div className="post-content">
        <h3 className="post-title">HELP for patients in India</h3>
        <p className="post-text">
          <small className="text-muted">
            Join us in making a difference. Verified donations and support for
            real needs across India.
          </small>
        </p>
      </div>
    </div>
  )}

  <ShareModal
    show={isShareModalOpen}
    onClose={() => setIsShareModalOpen(false)}
    selectedPost={currentPost}
    onSelectUser={handleUserSelection}
  />
</div>
    </>
  );
};

export default Post;
