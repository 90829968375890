import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faSearch,
  faEnvelope,
  faBell,
  faPlusSquare,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css";
import UploadPost from "./UploadPost";
import Logout from "./Logout";
import io from "socket.io-client";
import axiosInstance from "./Interceptors/axiosInterceptor";
import socket from "../Socket";
// const socket = io('http://localhost:8001', {
//   transports: ['websocket'],
//   reconnection: true,
//   reconnectionAttempts: Infinity,
//   reconnectionDelay: 1000,
// }); // Adjust the URL to your server

const Sidebar = ({ users, onMenuClick }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [showNotificationsModal, setShowNotificationsModal] = useState(false);

  const handleNewMessage = (senderId) => {
    setUnreadMessages((prevState) => ({
      ...prevState,
      [senderId]: (prevState[senderId] || 0) + 1,
    }));
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    // Fetch notifications from API
    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get("/notifications", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        //console.log("notification list is here from api/notification",response.data); // Check if the data is coming correctly
        setNotificationsList(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
    const addNotification = (notification) => {
      setNotificationsList((prevNotifications) => {
        const isDuplicate = prevNotifications.some(
          (n) => n._id === notification._id
        );
        return isDuplicate
          ? prevNotifications
          : [notification, ...prevNotifications];
      });
    };

    socket.on("message", (message) => {
      handleNewMessage(message.senderId); // Update unread message count
      addNotification(message);
    });

    socket.on("like", addNotification);

    socket.on("comment", addNotification);

    socket.on("notification", (notification) => {
      addNotification(notification);
    });

    // Cleanup on unmount
    return () => {
      socket.off("message");
      socket.off("like");
      socket.off("comment");
      socket.off("notification");
    };
  }, []);

  const markAsRead = async (notificationId) => {
    try {
      await axiosInstance.put(`/notifications/${notificationId}/read`);
      setNotificationsList((prevNotifications) =>
        prevNotifications.map((n) =>
          n._id === notificationId ? { ...n, read: true } : n
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const unreadNotificationCount = notificationsList.filter(
    (n) => !n.read
  ).length;

  const menuItems = [
    { name: "Home", icon: faHome, action: () => onMenuClick("Post") },
    { name: "Search", icon: faSearch, action: () => onMenuClick("Search") },
    {
      name: "Messages",
      icon: faEnvelope,
      action: () => onMenuClick("Messages"),
    },
    {
      name: "Notifications",
      icon: faBell,
      action: () => setShowNotificationsModal(true),
    },
    { name: "Create", icon: faPlusSquare, action: handleShow },
    { name: "Profile", icon: faUser, action: () => onMenuClick("Profile") },
    // {
    //   name: "Feedback",
    //   icon: faBell,
    //   action: () => onMenuClick("FeedbackForm"),
    // },
  ];

  return (
    <>
      {/* Top bar with logo and logout for small screens */}
      <div className="d-lg-none d-flex justify-content-between align-items-center p-2 border-bottom bg-body-secondary z-0 fixed-top">
        <Link to="/home/post" className="text-dark text-decoration-none">
          <h2 className="ms-3">
            <b>Needia</b>
          </h2>
        </Link>
        <Logout />
      </div>

      {/* Sidebar for larger screens */}
      <div className="sidebar d-none d-lg-flex flex-column vh-100 p-3 bg-body-secondary">
        <div className="sidebar-header d-flex justify-content-between align-items-center mb-3">
          <Link to="/home/post" className="text-dark text-decoration-none">
            <h2>
              <b>Needia</b>
            </h2>
          </Link>
          <Logout />
        </div>
        <ul className="sidebar-menu list-unstyled p-0 ">
          {menuItems.map((item, index) => (
            <li key={index} className="sidebar-menu-item mb-3 ">
              <div
                className="sidebar-menu-link d-flex align-items-center text-dark text-"
                onClick={item.action}
              >
                <FontAwesomeIcon icon={item.icon} className="me-2" />
                <span className="sidebar-menu-text">
                  <span className="fs-6">{item.name}</span>
                  {item.name === "Notifications" &&
                    unreadNotificationCount > 0 && (
                      <span className="badge bg-danger ms-2 ">
                        {unreadNotificationCount}
                      </span>
                    )}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Bottom tab bar for smaller screens */}
      <div className="d-lg-none fixed-bottom bg-body-secondary p-2 border-top d-flex justify-content-around">
        {menuItems.map((item, index) => (
          <div key={index} className="text-center" onClick={item.action}>
            <FontAwesomeIcon icon={item.icon} size="lg" className="text-dark" />
            <span className="sidebar-menu-text">
              {item.name === "Notifications" && unreadNotificationCount > 0 && (
                <span className="badge bg-danger ms-2">
                  {unreadNotificationCount}
                </span>
              )}
            </span>
            <div className="small">{item.name}</div>
          </div>
        ))}
      </div>

      {/* Modal for creating a post */}
      <Modal show={showModal} onHide={handleClose} centered >
        <Modal.Header closeButton>
          <Modal.Title>Create Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UploadPost onSuccess={() => setShowModal(false)} />
        </Modal.Body>
      </Modal>

      {/* Notifications Modal */}
      <Modal
        show={showNotificationsModal}
        onHide={() => setShowNotificationsModal(false)}
        dialogClassName="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {notificationsList.map((notification) => (
              <li
                key={notification._id}
                onClick={() => markAsRead(notification._id)}
              >
                {notification.content}{" "}
                {!notification.read && (
                  <span className="badge bg-primary ms-2">New</span>
                )}
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
