
import React, { useEffect, useState, useCallback } from "react";
import axiosInstance from "./Interceptors/axiosInterceptor";
import { useNavigate } from "react-router-dom";
import Socket from "../Socket"; // Import the socket instance
import "./UserList.css"; // Add CSS for styling the new message bubble and highlighted user
//import empty from "https://storage.googleapis.com/bucket1-for-backgroundimages/3d-fluency-people.png";
const UserList = ({ onSelectUser }) => {
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");

  // Fetch users once and set the user details map
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get("/users");
        const usersData = response.data;

        // Map user details for quick lookup
        const userDetailsMap = {};
        usersData.forEach((user) => {
          userDetailsMap[user.userId] = user;
        });

        setUserDetails(userDetailsMap);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchUsers(); // Only fetch users once when the component mounts
  }, []);

  // Fetch conversations and update the users state
  const fetchConversations = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/conversations");
      const conversations = response.data;

      //console.log("here conversation api data==>", conversations);
      // Sort conversations based on the latest message or updatedAt field
      const sortedConversations = conversations.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });

      // Transform sorted conversations to map users from conversations
      const sortedUsers = sortedConversations.map((conversation) => {
        const otherParticipant = conversation.participants.find(
          (participant) => participant !== userId
        );
        return {
          userId: otherParticipant,
          latestMessage: conversation.latestMessage,
          updatedAt: conversation.updatedAt,
          fullName: userDetails[otherParticipant]?.fullName || "Unknown User",
          unreadCounts: conversation.unreadCounts, // Add unreadCount from the backend
        };
      });

      //console.log("here is sorted users details with unreadcount===>",sortedUsers  );
      setUsers(sortedUsers);
      return sortedUsers;
    } catch (error) {
      console.error("Failed to fetch conversations:", error);
    }
  }, [userId, userDetails]);

  // Fetch conversations when the component mounts or when userDetails changes
  useEffect(() => {
    if (Object.keys(userDetails).length > 0) {
      // Ensure we only fetch conversations once user details are loaded
      fetchConversations();
    }
  }, [fetchConversations, userDetails]);

  // Set up socket connections
  useEffect(() => {
    Socket.emit("join", userId); // User joins their own socket room

    Socket.on("connect", () => {
     // console.log("Socket connected", Socket.id);
    });

    Socket.on("receiveMessage", (message) => {
      const { senderId } = message;
    
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.userId === senderId) {
            return {
              ...user,
              unreadCounts: (user.unreadCounts || 0) + 1, // Increment unread count properly
              latestMessage: message,
              updatedAt: message.createdAt,
            };
          }
          return user;
        })
      );
    });
    //r any update on conversation
    Socket.on("conversationUpdated", () => {
      fetchConversations();
    });
    // Listen for new messages (both sent and received)

    const handleNewMessage = (message) => {
    //  console.log("New message received or sent:", message);
    };
    // Listen for new messages sent and received
    //Socket.on('messageSent', handleNewMessage);
   // Socket.on("receiveMessage", handleNewMessage);

    // Cleanup socket listeners on unmount

    Socket.on("disconnect", () => {
     // console.log("Socket disconnected");
    });

    Socket.on("reconnect", () => {
     // console.log("Socket reconnected");
    });

    Socket.on("messageSent", (message) => {
     // console.log("Message sent event:", message);
    });

    Socket.on("receiveMessage", (message) => {
     // console.log("Message received event:", message);
    });

    Socket.on("conversationUpdated", ({ userId, conversationId }) => {
      // Handle conversation update, e.g., refetch conversations, reset badge count, etc.
     // console.log( `Conversation ${conversationId} for user ${userId} was updated`);
      fetchConversations(); // Refetch the conversations to sync the state
    });

    // Cleanup socket listeners on unmount
    return () => {
      Socket.off("messageSent", handleNewMessage);
      Socket.off("receiveMessage", handleNewMessage);
      Socket.off("connect");

      Socket.off("conversationUpdated");
      //  Socket.off('messageSent');
      //  Socket.off('receiveMessage');

      Socket.off("disconnect");
      Socket.off("reconnect");
    };
  }, [userId, fetchConversations]);

  // When user is clicked, reset new message count
  const handleSelectUser = async (user) => {
    onSelectUser(user);

    try {
      //console.log("before hitting the API to mark conversation as read");

      // Send a request to the backend to mark messages as read
      const respp = await axiosInstance.put(
        `/conversations/${user.userId}/markAsRead`
      );
     // console.log("API call to mark conversation as read completed", respp);

      setUsers((prevUsers) =>
        prevUsers.map((u) => {
          if (u.userId === user.userId) {
            return { ...u, unreadCounts: 0 }; // Reset the unread count to 0
          }
          return u;
        })
      );
      //console.log("here total data from marks read api====>", respp);
      Socket.emit("conversationUpdated", {
        userId,
        conversationId: user.userId,
      });

      // Introduce a short delay before re-fetching conversations
      setTimeout(async () => {
        await fetchConversations();
        //console.log("after done all updating in database read count", k);
      }, 300); // Delay of 300 milliseconds to allow DB to commit changes
    } catch (error) {
      console.error("Failed to mark conversation as read:", error);
    }
  };

  return (
    <div className="userlist container-fluid">
  {/* Header with back button and title */}
  <div className="d-flex align-items-center mb-3 p-2 bg-white">
    <button
      className="btn btn-primary me-5"
      onClick={() => navigate("/home/search")}
    >
      <span>&larr;</span>
    </button>
    <span className="fw-bold flex-grow-1 text-center">Messages</span>
  </div>

  {/* Conditional rendering for empty user list */}
  {users.length === 0 ? (
    <div className="empty-state d-flex flex-column align-items-center justify-content-center">
      {/* Placeholder Illustration */}
      <img 
        src="https://storage.googleapis.com/background-static-images/3d-fluency-people.png"// Replace with your illustration's path or URL
        alt="No Messages"
        className="empty-state-img mb-3"
        style={{ maxWidth: "150px" }}
      />
      {/* Friendly Message */}  
      <p className="text-muted">No conversations yet! Start a chat and connect with people.</p>
    </div>
  ) : (
    // User list with responsive layout
    users.map((user) => {
      const messageCount = user.unreadCounts || 0;
      return (
        <div
          key={user.userId}
          className={`user-item d-flex align-items-center justify-content-between p-2 rounded my-1 ${
            messageCount ? "highlighted bg-light" : ""
          }`}
          onClick={() => handleSelectUser(user)}
        >
          <div className="flex-grow-1">
            <strong>{user.fullName}</strong>
            <p className="text-muted small mb-0">
              {user.latestMessage?.content}
            </p>
            <span className="text-muted small">
              {new Date(user.updatedAt).toLocaleString()}
            </span>
          </div>
          
          {/* Conditional unread count bubble */}
          {messageCount > 0 && (
            <span className="badge bg-primary ms-2">{messageCount}</span>
          )}
        </div>
      );
    })
  )}
</div>
  );
};

export default UserList;
