import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Home from './Home';
const AuthLayout = () => {
  

  return (
    <>
   
    <div >
      <Home/>
     <Outlet/> 
  </div>
  </>
  );
};

export default AuthLayout;
