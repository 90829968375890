import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axiosInstance from "./Interceptors/axiosInterceptor";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

function VolunteerSignup() {
  const [fullName, setFullName] = useState("");
  const [college, setCollege] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();


  const validateAlphabeticInput = (value) => value==="" || /^[A-Za-z\s]+$/.test(value);

    const handleFullNameChange = (e) => {
    const value = e.target.value;
    if (validateAlphabeticInput(value)) {
      setFullName(value);
      setError(""); // Clear error if valid
    } else {
      setError("Full name should contain only letters and spaces.");
    }
  };
  const validateGmail = (value) => /^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(value);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUsername(value);
const isValid = validateGmail(value);
    setIsEmailValid(isValid); // Update email validity
    if (!isValid) {
      setError("Please enter a valid Gmail address.");
    } else {
      setError("");
    }
  };

  const handleCollegeChange = (e) => {
    const value = e.target.value;
    if (validateAlphabeticInput(value)) {
      setCollege(value);
      setError(""); // Clear error if valid
    } else {
      setError("College name should contain only letters and spaces.");
    }
  };


   // Password validation function
   const validatePassword = (value) => {
    // Check for minimum length, uppercase, lowercase, number, and special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(value);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (!validatePassword(value)) {
      setError(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
      );
    } else {
      setError("");
    }
  };
  // Send OTP to email
  const handleSendOtp = async () => {
    setError('');
    try {
      const response = await axios.post("https://needia.in/api/send-otp", { username });
      if (response.data.message === "OTP sent successfully") {
        setIsOtpSent(true);
        setError("OTP has been sent to your email.");
      } else {
        setError("Error sending OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during OTP send:", error);
      setError("Error sending OTP. Please try again.");
    }
  };
 // Verify OTP
 const handleVerifyOtp = async () => {
  try {
    const response = await axios.post("https://needia.in/api/verify-otp", { username, otp });
    if (response.data.message === "OTP verified successfully") {
      setIsOtpVerified(true);
      setError('');
    } else {
      setError("Invalid OTP. Please try again.");
    }
  } catch (error) {
    console.error("Error verifying OTP:", error);
    setError("Invalid OTP. Please try again.");
  }
};

  async function handleVolunteerSignup(e) {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await axiosInstance.post("/volunteerSignup", {
        fullName,
        college,
        username,
        password,
      });

      if (response.data === "exist") {
        setError(
          "Volunteer already exists. Please login with your credentials."
        );
      } else if (response.data === "volunteerSignupSuccess") {
        navigate("/", { state: { id: username } });
      }
    } catch (error) {
      console.error("Error during signup:", error);
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <form onSubmit={handleVolunteerSignup} className="w-100">
      <div className="form-group mb-1">
        <label htmlFor="fullName" className="form-label">
          Full Name
        </label>
        <input
          type="text"
          id="fullName"
          value={fullName}
          placeholder="Enter your full name"
          onChange={handleFullNameChange}
          className="form-control"
          required
          aria-label="Full Name"
        />
      </div>
      <div className="form-group mb-1">
        <label htmlFor="college" className="form-label">
          College
        </label>
        <input
          type="text"
          id="college"
          value={college}
          placeholder="Enter your college name"
          onChange={handleCollegeChange}
          className="form-control"
          required
          aria-label="College"
        />
      </div>
      <div className="form-group mb-1">
        <label htmlFor="username" className="form-label">
          Username(Email)
        </label>
        <input
          type="text"
          id="username"
          value={username}
          placeholder="Enter your username"
          onChange={handleEmailChange}
          className="form-control"
          required
          aria-label="Username"
        />
       {isEmailValid && (
          <button
            type="button"
            className="btn btn-secondary text-dark mt-3"
            onClick={handleSendOtp}
            disabled={isOtpSent}
            style={{ width: "90px", height: "20px" }}
          >
            {isOtpSent ? "OTP Sent" : "Verify Email"}
          </button>
        )}
      </div>
      {isOtpSent && (
        <div className="form-group mb-1">
          <label htmlFor="otp" className="form-label">Enter OTP</label>
          <input
            type="text"
            id="otp"
            value={otp}
            placeholder='Enter the OTP sent to your email'
            onChange={(e) => setOtp(e.target.value)}
            className="form-control"
            required
            aria-label="OTP"
          />
          <button
            type="button"
            className="btn btn-sm btn-secondary mt-3 text-dark" 
            onClick={handleVerifyOtp}
            disabled={isOtpVerified}
            style={{width:"90px", height:"20px"}}
          >
            {isOtpVerified ? "OTP Verified" : "Verify OTP"}
          </button>
        </div>
      )}
      <div className="form-group mb-2 position-relative">
        <label htmlFor="password" className="form-label">
          Password
        </label>
        <input
          className="form-control"
          type={passwordVisible ? "text" : "password"}
          id="password"
          value={password}
          placeholder="Enter your password"
          onChange={handlePasswordChange}
          required
          aria-label="Password"
          style={{ paddingRight: "40px" }}
        />
        <button
          type="button"
          className="btn btn-link position-absolute"
          style={{
            textDecoration: "none",
            right: "10px",
            top: "50%",
            transform: "translateY(10%)",
            width:"20px",
            padding:0,
          }}
          onClick={togglePasswordVisibility}
          aria-label={passwordVisible ? "Hide password" : "Show password"}
        >
          <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
        </button>
      </div>
      <button
        type="submit"
        className="btn btn-primary w-100"
        disabled={!isOtpVerified || loading}
      >
        {loading ? "Signing up..." : "Sign up"}
      </button>
      {error && <div className="alert alert-danger mt-1">{error}</div>}

      <div className="text-center">
        <Link to="/" className="btn btn-link">
          Login page
        </Link>
      </div>
    </form>
  );
}

export default VolunteerSignup;
