import React, { useState } from 'react';
import axiosInstance from './Interceptors/axiosInterceptor';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
function FeedbackForm() {
  const [subject, setSubject] = useState('Complaints');
  const [message, setMessage] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId'); // Ensure userId is stored in localStorage

    try {
      const token = localStorage.getItem('token');
      await axiosInstance.post(
        '/feedback',
        {
          userId,
          subject,
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Reset form and show thank-you message
      setSubject('Complaints');
      setMessage('');
      setFeedbackSubmitted(true);

      // Hide the thank-you message after a few seconds
      setTimeout(() => setFeedbackSubmitted(false), 3000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback');
    }
  };

  return (
    <div className="container mt-4">
       {/* Header with Back Button */}
       <div className="d-flex align-items-center mb-3 p-2 bg-white">
    <button
      className="btn btn-primary me-5 btn-lg"
      onClick={() => navigate("/home/post")}
    >
      <span>Back To Home</span>
    </button>
    <span className="fw-bold flex-grow-1 text-center">Feedback Form</span>
  </div>

      
      {feedbackSubmitted && (
        <div className="alert alert-success text-center" role="alert">
          Thank you for your feedback!
        </div>
      )}

      <form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow-sm">
      <div className="mb-3 d-flex flex-column flex-md-row align-items-center">
  <label htmlFor="subject" className="form-label me-md-3 mb-2 mb-md-0">
    Subject:
  </label>
  <select
    id="subject"
    value={subject}
    onChange={(e) => setSubject(e.target.value)}
    className="form-select"
    style={{ width: '100%' }} // Make select full-width on smaller screens
  >
    <option value="Complaints">Complaints</option>
    <option value="Issues">Issues</option>
    <option value="New Features">New Features</option>
    <option value="Other">Other</option>
  </select>
</div>


        <div className="mb-3">
          <label htmlFor="message" className="form-label">Your Feedback:</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            placeholder="Type your feedback here"
            className="form-control"
            required
          />
        </div>

        <button type="submit" className="btn btn-primary w-100">Submit</button>
      </form>
        {/* FAQ Section with Accordions */}
        <div className="mt-4">
        <h4>Frequently Asked Questions</h4>
        <Accordion defaultActiveKey="0" className="my-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>How do I submit feedback?</Accordion.Header>
            <Accordion.Body>
              To submit feedback, fill out the subject and message fields, then click the "Submit" button. Make sure to choose the most relevant subject for your feedback.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>What type of feedback can I provide?</Accordion.Header>
            <Accordion.Body>
              You can provide feedback on various topics such as complaints, issues, suggestions for new features, or any other feedback you may have. Select the appropriate subject to help us categorize your feedback.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default FeedbackForm;
